<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template slot="body">
            <div class="setting_name">
                <form-input
                    v-model="$v.firstname.$model"
                    outlined
                    legend="Firstname"
                    :field.sync="firstname"
                    :errors="validationMessage($v.firstname)"
                    :is-valid="$v.firstname.$dirty && !$v.firstname.$anyError"
                    @input.native="$v.firstname.$touch()"
                    @blur.native="$v.firstname.$touch()"
                />
                <form-input
                    v-model="$v.lastname.$model"
                    outlined
                    legend="Lastname"
                    :field.sync="lastname"
                    :errors="validationMessage($v.lastname)"
                    :is-valid="$v.lastname.$dirty && !$v.lastname.$anyError"
                    @input.native="$v.lastname.$touch()"
                    @blur.native="$v.lastname.$touch()"
                />
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers, mapGetters } from 'vuex';
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/profile/profile-name/rules'
import { formMessages } from '@/validation/profile/profile-name/messages'

import { eventBus } from '@/helpers/event-bus/'
import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import {
    ACTION_CLIENT_UPDATE_NAME
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    metaInfo: {
        title: 'Change account name'
    },
    data() {
        return {
            regionsLength: null,
            loading: false,
            firstname: '',
            lastname: ''
        }
    },
    validations: {
        ...validationRules
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ])
    },
    created() {
        const { firstname, lastname } = this.getterMainClientInfo
        this.firstname = firstname
        this.lastname = lastname
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions({
            updateName: ACTION_CLIENT_UPDATE_NAME
        }),
        async onSave() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            try {
                this.loading = true
                await this.updateName({
                    firstname: this.firstname,
                    lastname: this.lastname
                })
                eventBus.$emit('showSnackBar', 'Your account name has been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.loading = false
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style lang="scss">
    .setting_name{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;
        @media all and (max-width: 768px){
            width: 100%;
        }
    }
</style>
